import React from 'react';

import Page from '@macanta/components/Page';
import Head from '@macanta/components/Head';

const NotFoundPage = () => (
  <Page>
    <Head title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Page>
);

export default NotFoundPage;
